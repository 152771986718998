// const passField = document.querySelectorAll('input');
// const showPassword = document.querySelectorAll('form__show-password svg');

// showPassword.onclick = ( () => {
//   if (passField.type === 'password') {
//     passField.type = 'text';
//     showPassword.classList.add('hide-btn');
//   } else {
//     passField.type = 'password';
//     showPassword.classList.remove('hide-btn');
//   }
// })


