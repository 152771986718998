import './components/upload.js';
import './components/sliders.js';
import './components/select.js';
import './components/steps.js';
import './components/burger.js';
import './components/spollers.js';
import './components/comments.js';
import './components/dropdown.js';
import './components/showpassword.js';
import './components/banner.js';
import './components/tabs.js';
import './components/modals.js';
import './components/scrolltoanchor.js';
import './components/changehref.js';
import './components/parallaxphone.js';
import './components/closetimer.js';


